import React from 'react';
import styled from 'styled-components';
import theme from 'assets/styles/theme';
import LeftArrow from "assets/images/svg/left-arrow.svg";
import {Link} from "gatsby";
import NumericLabel from "components/NumericLabel";

const MainWrapper = styled.div`
  color: white;
  font-weight: bold;
  min-height: 50px; 
  display: flex;
  justify-content: ${({name, link}) => (name || link ? 'space-between' : 'flex-end')};
  align-items: center;
  position: ${({fixed}) => (fixed ? 'fixed' : 'initial')};
  top: 0;
  left: 0;
  padding: ${({fixed, padding}) => (fixed && theme.padding.layout) || (padding && '0 ' + theme.padding.layout) || '0' };
  margin-bottom: 1rem;
  width: 100%;
`;

const NameWrapper = styled.div`
  flex: 1;
  padding: 0 0 0 1em;
`;

const PointsWrapper = styled(Link)`
    font-size: 1.3rem;
    color: white;
    text-decoration: none;

    span {
        border-radius: 50%;
        color: ${theme.color.mainBlueColor};
        display: inline-block;
        font-weight: bold;
        margin: 0 0 0 10px;
        text-align: center;
        width: 3em;
        border: 2px solid;
        height: 3em;
        line-height: 2.8;
        font-size: 1.2rem;
    }
`;

const TopBar = props => {
    return (
        <MainWrapper name={props.name} link={props.link} fixed={props.fixed} padding={props.padding}>
            {props.link &&
            <Link to={props.link} state={props.state && props.state}>
                <LeftArrow/>
            </Link>
            }

            {props.name &&
            // Do widoku ustawień - imię i nazwisko użytkownika
            <NameWrapper>
                {props.name}
            </NameWrapper>
            }

            {props.points >= 0 &&
            <PointsWrapper to="/app/more/account/">
                Twoje punkty
                <NumericLabel number={props.points}/>
            </PointsWrapper>
            }

        </MainWrapper>
    );
};

export default TopBar;
