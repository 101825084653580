import React from 'react';
import styled from "styled-components";
import SectionHeader from "components/SectionHeader";
import {graphql, StaticQuery} from "gatsby";
import {translate} from "services/translate";
import Button from "components/Button";
import TopBar from "components/TopBar";
//TODO: Snackbar do zewnętrznego komponentu
import Snackbar from '@material-ui/core/Snackbar';
import Loader from "components/Loader";

import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextInput from "components/Forms/TextInput";

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  background: url(${({background}) => (background ? background : '')})
      no-repeat -470px top fixed;
  background-size: cover; 
  justify-content: center;
  position: relative;

  &:before {
    background: -webkit-linear-gradient(bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,0.3) 100%);
    position: absolute;
    width: 100%;
    min-height: 100vh;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
  }
`;

const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;

  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

class RemindScreen extends React.Component {
    remindPasswordForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]],
    });

    constructor(props) {
        super(props);

        this.state = {
            error: "",
            showSnackbar: false
        };
    }

    handleSubmit = event => {
        event.preventDefault();

        const firebase = require("firebase");
        const auth = firebase.auth();
        const self = this;

        this.setState({
            loading: true
        });

        auth.sendPasswordResetEmail(this.remindPasswordForm.value.email).then(function () {
            self.setState({
                error: {},
                showSnackbar: true,
                loading: false
            });
        }).catch(function (error) {
            self.setState({
                loading: false,
                error: {
                    code: error.code,
                    message: translate(error.code)
                }
            })
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
        })
    };

    render() {
        if (typeof window !== "undefined") {
            return (
                <StaticQuery
                    query={graphql`
                {
                    loginBackground: file(relativePath: { eq: "login.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                    render={data => (
                        <Wrapper background={data.loginBackground.childImageSharp.fluid.src}>
                            {this.state.loading &&
                            <Loader/>
                            }

                            <TopBar link="/login" fixed="true"/>
                            <SectionHeader header="Przypomnij hasło" center="true"/>
                            <FormWrapper>
                                <FieldGroup
                                    control={this.remindPasswordForm}
                                    render={({get, invalid}) => (
                                        <form onSubmit={this.handleSubmit}>

                                            <FieldControl
                                                name="email"
                                                render={TextInput}
                                                meta={{label: "Adres e-mail", style: "bordered"}}
                                            />

                                            <Button
                                                type="submit"
                                                disabled={invalid}
                                                title="Wyślij link"
                                            />
                                        </form>
                                    )}
                                />

                                {this.state.error &&
                                <div className="error">
                                    <div>{this.state.error.message}</div>
                                </div>
                                }

                                <Snackbar
                                    className="success"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={this.state.showSnackbar}
                                    autoHideDuration={2000}
                                    onClose={this.handleSnackbarClose}
                                    message={<span>Sprawdź swoją skrzynkę pocztową</span>}
                                />
                            </FormWrapper>
                        </Wrapper>
                    )}
                />
            );
        }
        return null;
    }
}

export default RemindScreen;
